import "./App.css";

import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";

// import debug_file from "./debug_file.json";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // without time
      date: new Date().toLocaleDateString(
        localStorage.getItem("lang") || "de-DE",
        { timeZone: "Europe/Berlin" }
      ),
      // only time
      time: new Date().toLocaleTimeString(
        localStorage.getItem("lang") || "de-DE",
        { timeZone: "Europe/Berlin" }
      ),
      sprintNr: 1,
      cardData: [0, 0, 0, 0, 0],
      cardLabel: ["Backlog", "Started", "Progressing", "Finalizing", "Done"],
      pi_chart_data: [
        { value: 0, color: "#6ec8f2" },
        { value: 0, color: "#ea5e80" },
      ],
      stopped: [],
      windowHeight: undefined,
      windowWidth: undefined,
    };
  }

  handleResize = () =>
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });

  getWeekNumber = (d) => {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - ((d.getUTCDay() + 6) % 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    const weekNr = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    // Return array of year and week number
    return Math.floor(weekNr / 2);
  };

  read_json = () => {
    // fetch current data from json "data_yyyy_mm_dd.json"
    const ts = new Date();

    const fullYear = ts.getFullYear();
    const month_leading_zero = (ts.getMonth() + 1).toString().padStart(2, "0");
    const date_leading_zero = ts.getDate().toString().padStart(2, "0");

    const filename = `./data_${fullYear}_${month_leading_zero}_${date_leading_zero}.json`;

    // this.setState({
    //   pi_chart_data: [
    //     { value: debug_file.state_of_tasks.complete, color: "#6ec8f2" },
    //     { value: debug_file.state_of_tasks.incomplete, color: "#ea5e80" },
    //   ],
    //   cardData: debug_file.card_data,
    //   stopped: debug_file.stopped,
    //   update_time: debug_file.update_time,
    //   screensize: "X: 0, Y: 0",
    // });

    fetch(filename)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          pi_chart_data: [
            { value: data.state_of_tasks.complete, color: "#6ec8f2" },
            { value: data.state_of_tasks.incomplete, color: "#ea5e80" },
          ],
          cardData: data.card_data,
          stopped: data.stopped,
          update_time: data.update_time,
          screensize: "X: 0, Y: 0",
        });
      });
  };

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);

    // time to update time
    setInterval(() => {
      this.setState({
        date: new Date().toLocaleDateString(
          localStorage.getItem("lang") || "de-DE",
          { timeZone: "Europe/Berlin" }
        ),
        time: new Date().toLocaleTimeString(
          localStorage.getItem("lang") || "de-DE",
          { timeZone: "Europe/Berlin" }
        ),
      });
    }, 1000);

    // get current week number
    const weekNr = this.getWeekNumber(new Date());
    this.setState({
      sprintNr: weekNr,
    });

    setInterval(() => {
      this.read_json();
    }, 1000);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    return (
      <div className="App">
        <div className="header">
          <div className="header-date">
            <div className="header-date-day">{this.state.date}</div>
            <div className="header-time">{this.state.time}</div>
          </div>
          <div className="header-title desktop">RevoBITs Dashboard</div>
          <div className="header-title mobile">RevoBITs</div>
          <div className="header-nr">
            <div className="header-date-day">Sprint {this.state.sprintNr}</div>
          </div>
        </div>
        <div className="divider horizontal"></div>
        <div className="content">
          <div className="content-col left">
            <div className="content-header">State of Tasks</div>
            <div className="pie-chart-container">
              <PieChart
                margin={{
                  left: 25,
                  right: 25,
                  top: 0,
                  bottom: 0,
                }}
                series={[
                  {
                    data: this.state.pi_chart_data,
                    arcLabel: (item) =>
                      `${Math.round(
                        (item.value /
                          (this.state.pi_chart_data[0].value +
                            this.state.pi_chart_data[1].value)) *
                          100
                      )}%`,
                    innerRadius: this.state.windowWidth / 20,
                    paddingAngle: 5,
                    cornerRadius: 5,
                  },
                ]}
              />
            </div>
            <div className="update">
              <div className="update-header">Last Update:</div>
              <div className="update-time">{this.state.update_time}</div>
            </div>
          </div>
          <div className="divider vertical"></div>
          <div className="content-col right">
            <div className="content-header">State of Cards</div>
            <div className="bar-chart-container">
              <BarChart
                margin={{
                  left: 50,
                  right: 25,
                  top: 25,
                  bottom: 50,
                }}
                colors={["#6ec8f2"]}
                series={[
                  {
                    data: this.state.cardData.map((x) => x[0]),
                    stack: "total",
                    id: "as_planned",
                    color: "#94C748",
                  },
                  {
                    data: this.state.cardData.map((x) => x[1]),
                    // label: "needs support",
                    stack: "total",
                    id: "needs_support",
                    color: "#E2B203",
                  },
                  {
                    data: this.state.cardData.map((x) => x[2]),
                    // label: "needs support",
                    stack: "total",
                    id: "bonus",
                    color: "#388BFF",
                  },
                ]}
                xAxis={[{ data: this.state.cardLabel, scaleType: "band" }]}
                yAxis={[{ tickMinStep: 1 }]}
                labelStyle={{ fontSize: "6" }}
                sx={{
                  "& .MuiChartsAxis-directionX": {
                    "& .MuiChartsAxis-tickLabel": {
                      fontSize: "13px!important",
                      fill: "#ffffff!important",
                    },
                  },
                  "& .MuiChartsAxis-directionY": {
                    "& .MuiChartsAxis-tickLabel": {
                      fontSize: "13px!important",
                      fill: "#ffffff!important",
                    },
                  },
                  "& .MuiBarElement-series-pvId": {
                    fill: "#77bf69!important",
                  },
                }}
              />
            </div>
            <div className="divider horizontal"></div>
            <div className="stopped-skipped">
              <div className="stopped-skipped-header">Stopped/Skipped:</div>
              <ul className="stopped-skipped-list">
                {/* fill list dynamically from state */}
                {this.state.stopped.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
